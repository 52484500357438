export const defaultFile = [
  {
    "#Data operacji": "2019-08-13",
    "#Data księgowania": "2019-08-13",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "SKLEP",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-11,46",
    "#Saldo po operacji": "51 228,83",
    "": null,
  },
  {
    "#Data operacji": "2019-08-14",
    "#Data księgowania": "2019-08-14",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "Apteka",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-38,98",
    "#Saldo po operacji": "51 189,85",
    "": null,
  },
  {
    "#Data operacji": "2019-08-15",
    "#Data księgowania": "2019-08-15",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "ORLEN",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-54,82",
    "#Saldo po operacji": "51 135,03",
    "": null,
  },
  {
    "#Data operacji": "2019-08-17",
    "#Data księgowania": "2019-08-17",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "Gym",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-99,00",
    "#Saldo po operacji": "51 036,03",
    "": null,
  },
  {
    "#Data operacji": "2019-08-22",
    "#Data księgowania": "2019-08-22",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "Apteka",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-72,42",
    "#Saldo po operacji": "50 963,61",
    "": null,
  },
  {
    "#Data operacji": "2019-08-25",
    "#Data księgowania": "2019-08-25",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "APTEKA",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-9,99",
    "#Saldo po operacji": "50 953,62",
    "": null,
  },
  {
    "#Data operacji": "2019-08-25",
    "#Data księgowania": "2019-08-25",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "Restauracja",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-17,00",
    "#Saldo po operacji": "50 936,62",
    "": null,
  },
  {
    "#Data operacji": "2019-08-25",
    "#Data księgowania": "2019-08-25",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "Restauracja",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-3,00",
    "#Saldo po operacji": "50 933,62",
    "": null,
  },
  {
    "#Data operacji": "2019-08-26",
    "#Data księgowania": "2019-08-26",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "APTEKA",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-11,99",
    "#Saldo po operacji": "50 921,63",
    "": null,
  },
  {
    "#Data operacji": "2019-08-26",
    "#Data księgowania": "2019-08-26",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "SKLEP",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-9,68",
    "#Saldo po operacji": "50 911,95",
    "": null,
  },
  {
    "#Data operacji": "2019-08-26",
    "#Data księgowania": "2019-08-26",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "SKLEP",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-4,10",
    "#Saldo po operacji": "50 907,85",
    "": null,
  },
  {
    "#Data operacji": "2019-08-27",
    "#Data księgowania": "2019-08-27",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "SKLEP",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-4,40",
    "#Saldo po operacji": "50 903,45",
    "": null,
  },
  {
    "#Data operacji": "2019-08-27",
    "#Data księgowania": "2019-08-27",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "SKLEP",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-6,98",
    "#Saldo po operacji": "50 896,47",
    "": null,
  },

  {
    "#Data operacji": "2019-08-30",
    "#Data księgowania": "2019-08-30",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "ORLEN",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-66,08",
    "#Saldo po operacji": "50 780,49",
    "": null,
  },
  {
    "#Data operacji": "2020-10-01",
    "#Data księgowania": "2020-10-01",
    "#Opis operacji": "PRZELEW ZEWNĘTRZNY PRZYCHODZĄCY",
    "#Tytuł": "Payment",
    "#Nadawca/Odbiorca": "",
    "#Numer konta": "",
    "#Kwota": "3 902,93",
    "#Saldo po operacji": "54 653,42",
    "": null,
  },
  {
    "#Data operacji": "2020-10-01",
    "#Data księgowania": "2020-10-01",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "SKLEP SPOZYWCZY",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-5,80",
    "#Saldo po operacji": "54 647,62",
    "": null,
  },
  {
    "#Data operacji": "2020-10-02",
    "#Data księgowania": "2020-10-02",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "APTEKA",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-25,80",
    "#Saldo po operacji": "54 621,82",
    "": null,
  },
  {
    "#Data operacji": "2020-10-02",
    "#Data księgowania": "2020-10-02",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "ORLEN",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-9,49",
    "#Saldo po operacji": "54 612,33",
    "": null,
  },
  {
    "#Data operacji": "2020-10-03",
    "#Data księgowania": "2020-10-03",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "McDonalds",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-8,20",
    "#Saldo po operacji": "54 604,13",
    "": null,
  },
  {
    "#Data operacji": "2020-10-04",
    "#Data księgowania": "2020-10-04",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "STOKROTKA",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-11,76",
    "#Saldo po operacji": "54 592,37",
    "": null,
  },
  {
    "#Data operacji": "2020-10-05",
    "#Data księgowania": "2020-10-05",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "Myjnia",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-4,00",
    "#Saldo po operacji": "54 588,37",
    "": null,
  },
  {
    "#Data operacji": "2020-10-05",
    "#Data księgowania": "2020-10-05",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "Myjnia",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-4,00",
    "#Saldo po operacji": "54 584,37",
    "": null,
  },
  {
    "#Data operacji": "2020-10-05",
    "#Data księgowania": "2020-10-05",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "Myjnia",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-4,00",
    "#Saldo po operacji": "54 580,37",
    "": null,
  },
  {
    "#Data operacji": "2020-10-05",
    "#Data księgowania": "2020-10-05",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "ORLEN",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-159,04",
    "#Saldo po operacji": "54 421,33",
    "": null,
  },
  {
    "#Data operacji": "2020-10-06",
    "#Data księgowania": "2020-10-06",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "PIZZERIA",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-32,90",
    "#Saldo po operacji": "54 388,43",
    "": null,
  },
  {
    "#Data operacji": "2020-10-07",
    "#Data księgowania": "2020-10-07",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "STOKROTKA",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-38,46",
    "#Saldo po operacji": "54 349,97",
    "": null,
  },
  {
    "#Data operacji": "2020-10-08",
    "#Data księgowania": "2020-10-08",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "SKLEP",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-2,30",
    "#Saldo po operacji": "54 347,67",
    "": null,
  },
  {
    "#Data operacji": "2020-10-09",
    "#Data księgowania": "2020-10-09",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "PayU*Allegro",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-105,00",
    "#Saldo po operacji": "54 222,68",
    "": null,
  },
  {
    "#Data operacji": "2020-10-10",
    "#Data księgowania": "2020-10-10",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "RESTAURACJA",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-15,00",
    "#Saldo po operacji": "54 207,68",
    "": null,
  },
  {
    "#Data operacji": "2020-10-10",
    "#Data księgowania": "2020-10-10",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "PayU*Allegro",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-8,99",
    "#Saldo po operacji": "54 198,69",
    "": null,
  },
  {
    "#Data operacji": "2020-10-11",
    "#Data księgowania": "2020-10-11",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "MEDIA MARKT",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-18,98",
    "#Saldo po operacji": "54 179,71",
    "": null,
  },
  {
    "#Data operacji": "2020-10-11",
    "#Data księgowania": "2020-10-11",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "SKLEP",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-6,70",
    "#Saldo po operacji": "54 173,01",
    "": null,
  },
  {
    "#Data operacji": "2020-10-12",
    "#Data księgowania": "2020-10-12",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "MCDONALDS",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-19,20",
    "#Saldo po operacji": "54 153,81",
    "": null,
  },
  {
    "#Data operacji": "2020-10-12",
    "#Data księgowania": "2020-10-12",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "ORLEN",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-59,50",
    "#Saldo po operacji": "54 094,31",
    "": null,
  },

  {
    "#Data operacji": "2020-10-14",
    "#Data księgowania": "2020-10-14",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "Bufet",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-16,10",
    "#Saldo po operacji": "54 042,21",
    "": null,
  },
  {
    "#Data operacji": "2020-10-14",
    "#Data księgowania": "2020-10-14",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "STOKROTKA",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-37,31",
    "#Saldo po operacji": "54 004,90",
    "": null,
  },
  {
    "#Data operacji": "2020-10-16",
    "#Data księgowania": "2020-10-16",
    "#Opis operacji": "PRZELEW ZEWNĘTRZNY PRZYCHODZĄCY",
    "#Tytuł": "Nagroda",
    "#Nadawca/Odbiorca": "",
    "#Numer konta": "",
    "#Kwota": "166,00",
    "#Saldo po operacji": "54 126,30",
    "": null,
  },
  {
    "#Data operacji": "2020-10-16",
    "#Data księgowania": "2020-10-16",
    "#Opis operacji": "PRZELEW ZEWNĘTRZNY PRZYCHODZĄCY",
    "#Tytuł": "Zwrot",
    "#Nadawca/Odbiorca": "",
    "#Numer konta": "",
    "#Kwota": "297,00",
    "#Saldo po operacji": "54 423,30",
    "": null,
  },

  {
    "#Data operacji": "2020-10-17",
    "#Data księgowania": "2020-10-17",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "DP*DECATHLON",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-89,97",
    "#Saldo po operacji": "54 321,83",
    "": null,
  },
  {
    "#Data operacji": "2020-10-19",
    "#Data księgowania": "2020-10-19",
    "#Opis operacji": "PRZELEW ZEWNĘTRZNY WYCHODZĄCY",
    "#Tytuł": "OPŁATA",
    "#Nadawca/Odbiorca": "",
    "#Numer konta": "",
    "#Kwota": "-906,40",
    "#Saldo po operacji": "53 415,43",
    "": null,
  },
  {
    "#Data operacji": "2020-10-20",
    "#Data księgowania": "2020-10-20",
    "#Opis operacji": "PRZELEW ZEWNĘTRZNY PRZYCHODZĄCY",
    "#Tytuł": "Zwrot",
    "#Nadawca/Odbiorca": "",
    "#Numer konta": "",
    "#Kwota": "283,50",
    "#Saldo po operacji": "53 698,93",
    "": null,
  },

  {
    "#Data operacji": "2020-10-28",
    "#Data księgowania": "2020-10-28",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł":
      "McDonalds",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-13,00",
    "#Saldo po operacji": "52 244,20",
    "": null,
  },

  {
    "#Data operacji": "2020-11-02",
    "#Data księgowania": "2020-11-02",
    "#Opis operacji": "PRZELEW ZEWNĘTRZNY PRZYCHODZĄCY",
    "#Tytuł": "Payment",
    "#Nadawca/Odbiorca": "",
    "#Numer konta": "",
    "#Kwota": "4 079,49",
    "#Saldo po operacji": "56 043,45",
    "": null,
  },
  {
    "#Data operacji": "2020-11-05",
    "#Data księgowania": "2020-11-05",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "SKLEP",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-4,80",
    "#Saldo po operacji": "56 038,65",
    "": null,
  },
  {
    "#Data operacji": "2020-11-05",
    "#Data księgowania": "2020-11-05",
    "#Opis operacji": "ZAKUP PRZY UŻYCIU KARTY",
    "#Tytuł": "ORLEN 01",
    "#Nadawca/Odbiorca": "  ",
    "#Numer konta": "''",
    "#Kwota": "-186,95",
    "#Saldo po operacji": "55 851,70",
    "": null,
  },
];
